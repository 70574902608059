import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'
import HIPAAForm from '../../../static/HIPAA-Privacy-form.pdf'
import RegistrationForm from '../../../static/Patient-registration-form.pdf'

interface Props {
  location: Location
}

const PatientRegistrationPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_img = data.section1_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/patient-registration/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Endodontics Patient Registration Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Endodontics Patient Registration Indianapolis IN"/>
        <meta name="description" content="Patient Registration from Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="og:description" content="Patient Registration from Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/patient-registration/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/patient-registration/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Endodontics Patient Registration Indianapolis IN"/>
        <meta name="twitter:description" content="Patient Registration from Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="patient-registration-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
      <div id="patient-registration-hero-section">
        <Container>
          <div className="col-lg-6 left-content pb-5">
            <h1 className="primary-text hero-heading">
              Patient Registration
            </h1>
            <p className="hero-text">
              Filling out the required forms ahead of time will expedite your visit to our office, and often ensures more accurate completion.
            </p>
            <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img" alt="happy lady"/>
          </div>
        </Container>
      </div>
    </BackgroundImage>
    <section id="faded-bg"></section>
    <section id="patient-registration-section-1">
    <Container>
      <div className="col-lg-12">
        <p>
          To reduce the time spent in the office, please complete all patient registration forms ahead of your appointment. This helps us expedite your consultation and ensure the information is completed accurately. For questions regarding your registration, please contact our office at <a href="tel:3178464980">317-846-4980.</a>
        </p>
        <p>
          Please download, print, and complete the patient registration form and HIPAA privacy form.
        </p>
        <p>
          Bring both forms, your insurance information, payment, and x-rays or referral information from your dentist.
        </p>
        <p className="pt-0">
          We look forward to seeing you soon!
        </p>
      </div>
      <div className="col-lg-6 text-center m-t-20">
        <h5>Patient Registration Form</h5>
        <Img fluid={section1_img} className="pdf-icon"/>
        {/*<a href="https://meridianendoindy.com/files/2016/11/076BE0C5-7837-44FA-98F7-CE753CE0DA2C.pdf" target="_blank">
          <button> Download Form</button>
        </a>*/}
        <a href={RegistrationForm} download >
          <button>Download Form</button>
        </a>
      </div>
      <div className="col-lg-6 text-center m-t-20">
        <h5>HIPAA Privacy form</h5>
        <Img fluid={section1_img} className="pdf-icon"/>
        {/*<a href="https://meridianendoindy.com/files/2016/11/0329F517-EFE4-446A-8346-1D1CC1A91F05.pdf" target="_blank">
          <button> Download Form</button>
        </a>*/}
        <a href={HIPAAForm} download >
          <button>Download Form</button>
        </a>
      </div>
    </Container>
    </section>

    <section id="patient-registration-section-3">
      <ContactForm/>
    </section>
    </Layout>
  )
}

export default PatientRegistrationPage

export const query = graphql`
  query PatientRegistrationPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "patient-reg-img" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_img: file(name: { eq: "pdf-icon" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
